import React from 'react';
import { PropertyNode } from '../types';

const renderDefinedByColumnBody = (node: PropertyNode) => (
  <div className='flex justify-center'>
    {node.data.isCustom ? (
      <i className='pi pi-user' title='User Defined' />
    ) : (
      <img
        src='./images/hubspot-logo.svg'
        alt='hs logo'
        style={{ maxWidth: '20px', filter: 'grayscale()' }}
        title='HubSpot Defined'
      />
    )}
  </div>
);

export default renderDefinedByColumnBody;
