import React, { useMemo, useState } from 'react';
import { useTokenContext } from './tokenContext';
import TokenTable from './TokenTable';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Fieldset } from 'primereact/fieldset';

const AssociatedTokens = () => {
  const { data, filterQuery } = useTokenContext();
  const [showInstructions, setShowInstructions] = useState<boolean>(false);

  const associationProps = useMemo(() => {
    return data?.filter((prop) => !!prop.relatedObjectProperties);
  }, [data]);

  return (
    <div className='flex flex-col'>
      <div className='flex py-3 max-w-xl self-center'>
        <button
          onClick={() => setShowInstructions(true)}
          className='border border-transparent rounded px-2 hover:bg-neutral-50 hover:border-neutral-300'
        >
          <i className='pi pi-compass mr-2' style={{ fontSize: '.8em' }} />
          Instructions
        </button>
      </div>

      <div className='flex overflow-auto'>
        <TokenTable data={associationProps || []} query={filterQuery} />
      </div>

      <Dialog
        header='Instructions'
        visible={showInstructions}
        onHide={() => setShowInstructions(false)}
        style={{ maxWidth: '900px', height: '90%' }}
      >
        <div className='flex flex-col gap-4'>
          <p>
            Associations are lists/arrays of related records. There are two common ways to display values from lists in
            Documint.
          </p>

          <ol className='flex flex-col gap-3 list-decimal pl-5'>
            <li>
              <p className='mb-3'>
                <b>Display values from single item:</b> You can display a specific item in a list of associated items.
                This is useful if there will only ever be a single associated item. For example <i>companies</i>{' '}
                associated with <i>deals</i>.
              </p>

              <Fieldset legend='Details' toggleable className='w-full' collapsed={true}>
                <div className='flex flex-col gap-4'>
                  <div className='border bg-neutral-50 rounded'>
                    <h4 className='mb-2 p-3 font-bold'>
                      To display values from a single item use the following syntax in your template:
                    </h4>
                    <Image src='./images/single-list-item-property.png' />
                  </div>

                  <div className='border bg-neutral-50 rounded'>
                    <p className='mb-2 p-3 font-bold'>
                      Select "First item" then copy an associated object property token.
                    </p>
                    <Image src='./images/copy-first-item-property.png' preview />
                  </div>

                  <div className='border bg-neutral-50 rounded'>
                    <p className='mb-2 p-3 font-bold'>Paste the token into the template</p>
                    <Image src='./images/paste-first-item-property.png' preview />
                  </div>
                </div>
              </Fieldset>
            </li>

            <li>
              <p className='mb-3'>
                <b>Display values from all items:</b> You can display values from each associated object by looping over
                them. This is useful when there are multiple associated objects and you want to display data from all of
                them. An example of this would be <i>Line Items</i> on the <i>Deals</i> Object.
              </p>
              <Fieldset
                legend='Details'
                toggleable
                className='w-full'
                collapsed={true}
                // pt={{ header: { className: 'flex flex-row-reverse justify-end gap-2' } }}
              >
                <div className='flex flex-col gap-4'>
                  <div className='border bg-neutral-50 rounded'>
                    <p className='mb-2 p-3 font-bold'>
                      Copy the associated token name (note it's not wrapped in curly braces){' '}
                    </p>
                    <Image src='./images/copy-loop-token.png' preview />
                  </div>

                  <div className='border bg-neutral-50 rounded'>
                    <p className='mb-2 p-3 font-bold'>
                      In your Documint template, select the parent element that will be repeated for each item in the
                      list then click "Edit logic"
                    </p>
                    <Image src='./images/all-items-properties-setup.png' preview />
                  </div>

                  <div className='border bg-neutral-50 rounded'>
                    <p className='mb-2 p-3 font-bold'>
                      Paste the associated token name into the "List variable" field.
                    </p>
                    <Image src='./images/all-items-properties-setup-2.png' preview />
                  </div>

                  <div className='border bg-neutral-50 rounded'>
                    <p className='mb-2 p-3 font-bold'>
                      In the HubSpot App, copy one of the associated object's properties.
                    </p>
                    <Image src='./images/copy-sub-property.png' preview />
                  </div>

                  <div className='border bg-neutral-50 rounded'>
                    <p className='mb-2 p-3 font-bold'>Paste it inside the repeating element in your template.</p>
                    <Image src='./images/paste-associated-object-properties.png' preview />
                  </div>
                </div>
              </Fieldset>
            </li>
          </ol>
        </div>
      </Dialog>
    </div>
  );
};

export default AssociatedTokens;
