import { useCallback, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useApiKey } from '../../hooks/authHooks';

interface Props {
  onFinish?: () => void;
  onError?: (error: Error) => void;
  onCancel?: () => void;
  cancellable?: boolean;
}

const ApiKeyForm = ({ onFinish, onCancel, cancellable = false, onError }: Props) => {
  const [value, setValue] = useState<string>();
  const { save, saveMeta } = useApiKey();

  const handleSubmit = useCallback(async () => {
    try {
      if (!value) return;
      await save(value).unwrap();
      onFinish && onFinish();
    } catch (error) {
      onError && onError(error as Error);
    }
  }, [value, onFinish, save, onError]);

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  return (
    <div className='flex flex-col gap-y-5 w-full justify-stretch'>
      <div>
        <InputText
          // value={value}
          onChange={(e) => setValue(e.target.value)}
          className='flex items-center w-full'
          placeholder='xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
        />
      </div>

      <div className='flex gap-3 justify-center'>
        {cancellable && (
          <Button outlined onClick={handleCancel}>
            Cancel
          </Button>
        )}
        <Button label='Save' loading={saveMeta.isLoading} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default ApiKeyForm;
