import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { selectSelectedTemplateId, setSelectedTemplate } from '../store/templateSlice';
import { selectTemplateById, selectAllTemplates } from '../store/templateSlice';
import { Template } from '../app/api/types';

export function useTemplates() {
  const templates = useAppSelector(selectAllTemplates);
  const selectedTemplateId = useAppSelector(selectSelectedTemplateId);
  const dispatch = useAppDispatch();

  const selectTemplate = useCallback(
    (template: string | Template) => {
      dispatch(setSelectedTemplate(template));
    },
    [dispatch],
  );

  const selectedTemplate = useAppSelector((state) => selectTemplateById(state, selectedTemplateId || ''));

  return useMemo(
    () => ({ selectedTemplate, templates, selectTemplate }),
    [templates, selectedTemplate, selectTemplate],
  );
}
