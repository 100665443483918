export const getCurrentParams = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const result: any = {};
  //@ts-ignore
  for (let [key, value] of params.entries()) {
    result[key] = value;
  }
  return result;
};
