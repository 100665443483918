import SideBar from './components/SideBar';
import DocumentViewer from './components/DocumentViewer';

const DashboardView = () => {
  return (
    <div style={{ height: '100vh' }} className='flex overflow-hidden no-wrap'>
      <div style={{ flex: '1 1 auto', background: '#F2F4FA' }}>
        <DocumentViewer />
      </div>
      <div style={{ flex: '0 0 300px' }} className='h-screen overflow-y-auto'>
        <SideBar />
      </div>
    </div>
  );
};

export default DashboardView;
