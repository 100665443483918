import { useCallback, useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
// import TemplateSelectModal from '../../Templates/components/TemplateSelectModal';
import SelectedTemplate from './SelectedTemplate';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config';
import { Template } from '../../../app/api/types';
import TokenListModal from './TokenListModal';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import { useLazyGetDebugInfoQuery } from '../../../app/api';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import TemplateSettingsModal from './TemplateSettingsModal';
import { useAuth } from '../../../hooks/authHooks';

import { useCreateDocumentMutation } from '../../../app/api/documents';
import { useCreatorMode } from '../../../hooks/uiHooks';
import { useTemplates } from '../../../hooks/templateHooks';

const SideBar = () => {
  const { user } = useAuth();
  const [creatorMode, { setCreatorMode }] = useCreatorMode();
  // const [showTemplateSelect, setShowTemplateSelect] = useState<boolean>(false);
  const [showTemplateSettings, setShowTemplateSettings] = useState<boolean>(false);
  const [showTokens, setShowTokens] = useState<boolean>(false);
  const [createDocument, document] = useCreateDocumentMutation({ fixedCacheKey: 'document' });
  const [getDebugInfo, getDebugInfoMeta] = useLazyGetDebugInfoQuery();
  const debugInfoLinkRef = useRef<HTMLAnchorElement | null>(null);
  const toast = useRef<Toast>(null);
  const initialized = useRef<boolean>(false);
  const { selectedTemplate } = useTemplates();
  const navigate = useNavigate();

  const goToTemplates = useCallback(()=>{
    navigate('/templates')
  },[navigate])



  useEffect(() => {
    if (!selectedTemplate) goToTemplates();
  }, [goToTemplates, selectedTemplate]);

  useEffect(() => {
    // Initialize
    if (!initialized.current) {
      if (user?.isAdmin && !creatorMode) setCreatorMode?.(true);
      initialized.current = true;
    }
  }, [user, setCreatorMode, creatorMode]);

  const generatePreview = useCallback(
    (template: Template | void) => {
      if (!selectedTemplate && !template) return;
      const templateId = template?.id || selectedTemplate?.id;
      if (!templateId) return;
      if (document.data) document.reset();
      createDocument({ templateId, isLive: false });
    },
    [selectedTemplate, createDocument, document],
  );

  useEffect(() => {
    if (selectedTemplate) createDocument({ templateId: selectedTemplate.id, isLive: false });
  }, [selectedTemplate, createDocument]);

  const generateDocument = useCallback(
    async (template: Template | void) => {
      try {
        if (!selectedTemplate && !template) return;
        const templateId = template?.id || selectedTemplate?.id;
        if (!templateId) return;

        if (document.data) document.reset();

        const { name } = await createDocument({ templateId, isLive: true }).unwrap();
        toast.current?.show({
          severity: 'success',
          summary: 'Document Generated',
          detail: (
            <>
              <b>{name}</b> has been saved to the <b>Notes</b> section of this record.
            </>
          ),
          life: 5000,
        });
      } catch (error) {
        toast.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An error was encountered while generating the document.',
          life: 3000,
        });
      }
    },
    [selectedTemplate, createDocument, document],
  );

  // const handleSelectTemplate = (template: Template) => {
  //   setShowTemplateSelect(false);
  //   generatePreview(template);
  // };

  const handleDownloadDebug = useCallback(async () => {
    try {
      if (getDebugInfoMeta.isFetching) return;
      const element = debugInfoLinkRef.current;
      if (!element) return;
      const { data } = await getDebugInfo();
      const payload: { [key: string]: any } = { ...data };
      if (selectedTemplate) payload.template = selectedTemplate;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(payload));
      element.setAttribute('href', dataStr);
      element.setAttribute('download', `debug-${data?.objectType || ''}-${data?.objectId}.json`);
      element.click();
    } catch (error) {
      console.log(error);
    }
  }, [getDebugInfo, getDebugInfoMeta, selectedTemplate]);

  const showConfirmDebugPrompt = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    confirmPopup({
      target: event.currentTarget,
      message: (
        <>
          Download debug info? <br />
          This is usually requested by Documint support.
        </>
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: handleDownloadDebug,
      acceptLabel: 'Download',
      acceptIcon: 'pi pi-download',
      rejectLabel: 'Cancel',
    });
  };

  return (
    <div className='flex flex-col gap-5 pl-4 pr-1 h-full justify-between'>
      <div className='flex flex-col gap-4'>
        {/* <h4 className='text-xl text-center mb-2'>Create a document</h4> */}

        <div className='mb-2'>
          {selectedTemplate ? (
            <SelectedTemplate onClick={goToTemplates} onClickSettings={() => setShowTemplateSettings(true)} />
          ) : (
            <Button
              onClick={goToTemplates}
              label='Select a template'
              className='w-full shadow-md shadow-primary-300 pulse-shadow-primary'
            />
          )}
        </div>

        <div className='flex flex-col gap-4'>
          {selectedTemplate && (
            <div className='flex flex-col gap-4'>
              {/* Notice about inactive template */}
              {selectedTemplate?.isActive === false && (
                <div className='w-full'>
                  <Message
                    text={
                      <span className='text-sm'>
                        Documents created from{' '}
                        <Link
                          to='https://docs.documint.me/documents/minted-documents'
                          className='underline'
                          target='_blank'
                        >
                          inactive
                        </Link>{' '}
                        templates are{' '}
                        <Link
                          to='https://docs.documint.me/documents/minted-documents'
                          className='underline'
                          target='_blank'
                        >
                          watermarked
                        </Link>
                        .
                      </span>
                    }
                    className='w-full text-sm'
                  />
                </div>
              )}

              {/* Generate Document Button */}

              <Button
                className='flex-auto'
                disabled={document?.isLoading}
                label='Generate'
                onClick={() => generateDocument()}
                tooltip='Generate document and save as note'
                tooltipOptions={{ showDelay: 1000, position: 'left' }}
              />
              {selectedTemplate && creatorMode && (
                <Button
                  type='button'
                  disabled={document?.isLoading}
                  label='Refresh preview'
                  outlined
                  className='flex-auto'
                  onClick={() => generatePreview()}
                  icon='pi pi-refresh'
                  tooltip='Re-generate the preview'
                  tooltipOptions={{ showDelay: 1000, position: 'left' }}
                />
              )}
            </div>
          )}
          {/* TOKENS BUTTON */}
          {creatorMode && (
            <Button onClick={() => setShowTokens(true)} outlined label={`View Tokens`} icon={<>&#123;...&#125;</>} />
          )}
        </div>
      </div>

      {/* Footer */}
      <div className='flex justify-around items-center'>
        <div className='flex flex-auto gap-3 items-center justify-start'>
          <span className='text-sm text-neutral-500'>Editor Mode</span>
          <InputSwitch
            checked={creatorMode}
            onChange={(e) => setCreatorMode?.(e.target.value)}
            style={{ scale: '.8' }}
          />
        </div>

        {/* Debug Info Download */}
        {creatorMode && (
          <div className='flex flex-auto justify-center'>
            <ConfirmPopup acceptLabel='Download' />
            <a href='void' aria-label='ignore' ref={debugInfoLinkRef} className='hide'></a>
            <span className='cursor-pointer' onClick={showConfirmDebugPrompt}>
              {getDebugInfoMeta.isFetching ? (
                <i className='pi pi-spin pi-spinner text-neutral-300' />
              ) : (
                <svg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 512 512'>
                  <path
                    fill='#bbb'
                    d='M256 0c53 0 96 43 96 96v3.6c0 15.7-12.7 28.4-28.4 28.4H188.4c-15.7 0-28.4-12.7-28.4-28.4V96c0-53 43-96 96-96zM41.4 105.4c12.5-12.5 32.8-12.5 45.3 0l64 64c.7 .7 1.3 1.4 1.9 2.1c14.2-7.3 30.4-11.4 47.5-11.4H312c17.1 0 33.2 4.1 47.5 11.4c.6-.7 1.2-1.4 1.9-2.1l64-64c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-64 64c-.7 .7-1.4 1.3-2.1 1.9c6.2 12 10.1 25.3 11.1 39.5H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H416c0 24.6-5.5 47.8-15.4 68.6c2.2 1.3 4.2 2.9 6 4.8l64 64c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-63.1-63.1c-24.5 21.8-55.8 36.2-90.3 39.6V240c0-8.8-7.2-16-16-16s-16 7.2-16 16V479.2c-34.5-3.4-65.8-17.8-90.3-39.6L86.6 502.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l64-64c1.9-1.9 3.9-3.4 6-4.8C101.5 367.8 96 344.6 96 320H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96.3c1.1-14.1 5-27.5 11.1-39.5c-.7-.6-1.4-1.2-2.1-1.9l-64-64c-12.5-12.5-12.5-32.8 0-45.3z'
                  />
                </svg>
              )}
            </span>
          </div>
        )}

        <div className='flex flex-auto justify-end'>
          <Link to={config.docsURL} className='text-primary-700' target='_blank'>
            <i className='pi pi-book mr-2' />
            Docs
          </Link>
        </div>
      </div>

      {/* MODALS & TOASTS */}
      <TokenListModal visible={showTokens} onHide={() => setShowTokens(false)} />

      {/* <TemplateSelectModal
        visible={showTemplateSelect}
        onHide={() => setShowTemplateSelect(false)}
        onSelect={handleSelectTemplate}
      /> */}

      <TemplateSettingsModal visible={showTemplateSettings} onHide={() => setShowTemplateSettings(false)} />

      <Toast ref={toast} position='bottom-left' />
    </div>
  );
};

export default SideBar;
