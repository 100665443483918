import { apiSlice } from '.';
import { AuthorizationUrlResponse, TokenValidityResponse } from './types';

export const hubspot = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkTokenValidity: builder.query<TokenValidityResponse, void>({
      query: () => ({ url: '/hubspot/token-validity' }),
    }),
    getAuthorizationUrl: builder.query<AuthorizationUrlResponse, void>({
      query: () => '/hubspot/authorization-url',
      providesTags: ['AuthorizationUrl'],
    }),
  }),
});

export const { useCheckTokenValidityQuery, useLazyCheckTokenValidityQuery } = hubspot;
