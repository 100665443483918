import { apiSlice } from '.';
import { Template, Document } from './types';

const documentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDocument: builder.mutation<Document, { templateId: Template['id']; isLive?: boolean }>({
      query: ({ templateId, isLive = false }) => {
        const config = { url: `/templates/${templateId}`, method: 'POST', params: {} };
        config.params = isLive ? { active: true } : { preview: true };
        return config;
      },
      transformResponse: (response: { result: Document }) => response.result,
    }),
  }),
});

export const { useCreateDocumentMutation } = documentApi;
