import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

type UiState = {
  creatorMode: boolean;
};
const initialState: UiState = {
  creatorMode: false,
};

type CreatorModeToggleParam = boolean | void;

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    enableCreatorMode(state: UiState) {
      state.creatorMode = true;
    },
    disableCreatorMode(state: UiState) {
      state.creatorMode = false;
    },
    toggleCreatorMode(state: UiState, action: PayloadAction<CreatorModeToggleParam>) {
      if (action.payload) {
        state.creatorMode = action.payload;
      } else {
        state.creatorMode = !state.creatorMode;
      }
    },
    setCreatorMode(state: UiState, action: PayloadAction<boolean>) {
      state.creatorMode = action.payload;
    },
  },
});

export const { enableCreatorMode, disableCreatorMode, toggleCreatorMode, setCreatorMode } = uiSlice.actions;

export const selectCreatorMode = (state: RootState) => state.ui.creatorMode;

export default uiSlice.reducer;
