import { useEffect } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

// Views
import DashboardView from './views/Dashboard';
import ApiKeyView from './views/ApiKey';
import Unauthorized from './views/Unauthorized';
import Login from './views/Login';
import Templates from './views/Templates';

import { ProtectedRoute } from './components/ProtectedRoute';
import Authorize from './views/Authorize';
import { TokenProvider } from './views/Dashboard/components/TokenListModal/tokenContext';
import { useAppDispatch } from './hooks/reduxHooks';
import { setObject } from './store/objectSlice';
import { setToken } from './store/authSlice';

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const objectId = search.get('associatedObjectId');
    const objectType = search.get('associatedObjectType');
    if (objectId && objectType) {
      dispatch(setObject({ objectId, objectType: objectType.toLowerCase() }));
    }

    const token = search.get('session_token');
    if (token) {
      dispatch(setToken(token));
    }
  }, [dispatch]);

  const router = createBrowserRouter([
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    { path: '/unauthorized', element: <Unauthorized /> },
    {
      path: '/dashboard',
      element: <ProtectedRoute component={<DashboardView />} />,
    },
    {
      path: '/templates',
      element: <ProtectedRoute component={<Templates />} />,
    },
    {
      path: '/api-key',
      element: <ProtectedRoute component={<ApiKeyView />} />,
    },
    {
      path: '/authorize',
      element: <Authorize />,
    },
    {
      path: '*',
      element: <Navigate to={'/'} />,
    },
  ]);

  return (
    <PrimeReactProvider>
      <TokenProvider>
        <RouterProvider router={router} />
      </TokenProvider>
    </PrimeReactProvider>
  );
};

export default App;
