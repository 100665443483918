import React, { useCallback, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import config from '../../../../config';
import { Link } from 'react-router-dom';

import { SelectButton } from 'primereact/selectbutton';
import SearchButtonInput from '../../../../components/SearchButtonInput';
import { useTokenContext } from './tokenContext';
import ObjectTokens from './ObjectTokens';
import AssociatedTokens from './AssociatedTokens';
import { useObject } from '../../../../hooks/objectHooks';

interface Props {
  visible: boolean;
  onHide: () => void;
}

const TokenListModal = ({ visible, onHide }: Props) => {
  const { view, setView, fetchQuery, setFilterQuery } = useTokenContext();
  const { objectType } = useObject();

  const handleQueryChange = useCallback(
    (value: string) => {
      setFilterQuery?.(value);
    },
    [setFilterQuery],
  );

  const handleHide = () => {
    setFilterQuery?.('');
    onHide?.();
  };

  useEffect(() => {
    fetchQuery?.();
  }, [fetchQuery]);

  const renderHeader = () => (
    <div className='flex items-center justify-between gap-5' style={{ paddingTop: '1px' }}>
      {/* LEFT */}
      <div className='flex items-center basis-1/5'>
        <SearchButtonInput onChange={handleQueryChange} />
      </div>
      {/* CENTER */}
      <div className='flex gap-4 items-center flex-auto justify-center'>
        <SelectButton
          value={view}
          onChange={(e) => setView?.(e.target.value)}
          options={[
            { label: `${objectType} Properties`, value: 'object' },
            { label: 'Association Properties', value: 'associated' },
          ]}
          pt={{ button: { className: 'p-button-sm capitalize', style: { padding: '6px 10px' } } }}
        />
      </div>

      {/* RIGHT */}
      <div className='flex items-center basis-1/5 justify-end'>
        <Link to={config.docsURL} target='_blank' className='p-button p-button-text p-button-sm'>
          <i className='pi pi-question-circle mr-2' />
          Help
        </Link>
      </div>
    </div>
  );
  return (
    <Dialog
      visible={visible}
      onHide={handleHide}
      style={{ width: 'calc(100vw - 20px)', height: 'calc(100vh - 20px)', maxHeight: 'none' }}
      header={renderHeader}
      draggable={false}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      headerStyle={{ borderBottom: 'solid 1px #ddd', padding: '.7em 1em' }}
    >
      {view === 'object' ? <ObjectTokens /> : <AssociatedTokens />}
    </Dialog>
  );
};

export default TokenListModal;
