import { useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, LoadError } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { Panel } from 'primereact/panel';
import { Skeleton } from 'primereact/skeleton';
import { getPageDimensions } from '../../../utils';
import { Message } from 'primereact/message';
import { useCreateDocumentMutation } from '../../../app/api/documents';
import { Image } from 'primereact/image';

const DocumentViewer = () => {
  const { data, isLoading, isSuccess } = useCreateDocumentMutation({ fixedCacheKey: 'document' })[1];
  const [loadError, setLoadError] = useState<LoadError | null>(null);
  const toolbarPluginInstance = toolbarPlugin();
  const { zoomPluginInstance, pageNavigationPluginInstance, getFilePluginInstance } = toolbarPluginInstance;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageInput, NumberOfPages } = pageNavigationPluginInstance;
  const { Download } = getFilePluginInstance;

  function renderError(error: LoadError) {
    setLoadError(error);
    return (
      <div className='w-full max-h-full overflow-y-auto'>
        <div className='flex justify-center '>
          <div className='w-full max-w-xl bg-white rounded-xl p-6 m-10'>
            <h3 className='text-center text-2xl mb-4'>Unable to load document</h3>
            <div className='flex flex-col gap-2 mb-5 text-md text-neutral-500'>
              <p>An error was encountered while loading the document.</p>
              <p>
                Try creating a new preview. If the error persists please contact{' '}
                <a href='mailto:support@documint.me'>Documint Support</a>
              </p>
            </div>
            <Panel header='Error details' toggleable className='w-full' collapsed={true}>
              {error.message}
            </Panel>
          </div>
        </div>
      </div>
    );
  }

  const Controls = () => {
    const controlGroupClass = 'flex flex-col items-center justify-center gap-3 text-center';
    return (
      <div className='absolute top-1/2 left-4 flex justify-center items-center z-20' style={{ height: '50px' }}>
        <div className='flex flex-col items-stretch bg-white py-3  rounded-lg grow-0 border border-neutral-100 shadow-md'>
          <div className={controlGroupClass}>
            <Download />
          </div>
          <hr className='mx-2 border-neutral-200 my-3' />
          <div className={controlGroupClass}>
            <ZoomInButton />
            <ZoomPopover />
            <ZoomOutButton />
          </div>
          <hr className='mx-2 border-neutral-200 my-3' />
          <div className={controlGroupClass}>
            <span className='text-neutral-400'>Page</span>
            <CurrentPageInput />
            <span className='text-neutral-400'>
              of <NumberOfPages />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id='document-viewer' className='relative' style={{ height: '100vh', backgroundColor: '#f4f7ff' }}>
      <div style={{ wordBreak: 'break-word' }} className='h-screen'>
        {isLoading ? (
          <PageLoader />
        ) : isSuccess && data?.url ? (
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
            {!loadError && <Controls />}
            <Viewer
              fileUrl={data.url}
              defaultScale={SpecialZoomLevel.PageFit}
              plugins={[toolbarPluginInstance]}
              renderError={renderError}
              renderLoader={() => <PageLoader />}
            />
          </Worker>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

function EmptyState() {
  return (
    <div className='flex flex-col px-10 gap-8 h-full items-center justify-center'>
      <div className='flex-grow flex flex-col justify-center items-center gap-10 w-full pt-5'>
        <h1 className='text-2xl text-sky-800'>Getting Started</h1>
        <div className='flex gap-10 text-center text-primary-900 justify-around w-full max-w-4xl'>
          <div className='flex flex-col gap-5 items-center w-1/3' style={{ maxWidth: '200px' }}>
            <img src='/images/getting-started-step-1.svg' className='w-full' alt='Step-1' />
            <p>Select your template</p>
          </div>
          <div className='flex flex-col gap-5  max-w-md w-1/3 items-center' style={{ maxWidth: '200px' }}>
            <img src='/images/getting-started-step-2.png' className='w-full' alt='Step-2' />
            <p>Preview and review your document</p>
          </div>
          <div className='flex flex-col gap-5  max-w-md w-1/3 items-center' style={{ maxWidth: '200px' }}>
            <img src='/images/getting-started-step-3.png' className='w-full' alt='Step-3' />
            <p>Generate your document and save it as a note</p>
          </div>
        </div>
      </div>
      <div className='flex-shrink py-5'>
        <Image src='./images/logo.svg' width='140px' className='' />
      </div>
    </div>
  );
}

function PageLoader() {
  const { width, height } = getPageDimensions({ width: '500px', height: '647px', ppi: 72 });
  const rows = 5;
  return (
    <div className='page-loader flex flex-col justify-start h-screen items-center relative overflow-y-auto w-full'>
      <div style={{ width, height }} className='bg-white flex flex-col p-12 gap-5 mt-2 shadow-lg'>
        <div className='flex justify-between'>
          <div className='flex flex-col items-end gap-3'>
            <Skeleton width='10rem' className='mb-2'></Skeleton>
            <Skeleton width='5rem' className='mb-2'></Skeleton>
          </div>

          <div className='flex flex-col items-end gap-3'>
            <Skeleton width='10rem' height='4rem'></Skeleton>
            <Skeleton width='10rem' className='mb-2'></Skeleton>
          </div>
        </div>

        <Skeleton className='mb-2'></Skeleton>

        <Skeleton height='2rem' className='mb-2'></Skeleton>
        <div className='flex flex-col gap-3'>
          {Array.from({ length: rows }).map((k, i) => (
            <div className='flex gap-6' key={i}>
              <Skeleton width='55%' className='mb-2'></Skeleton>
              <Skeleton width='15%' className='mb-2'></Skeleton>
              <Skeleton width='15%' className='mb-2'></Skeleton>
              <Skeleton width='15%' className='mb-2'></Skeleton>
            </div>
          ))}
        </div>
        <Skeleton width='10rem' className='mb-2'></Skeleton>
        <Skeleton height='2rem' className='mb-2'></Skeleton>
      </div>

      <div className='flex relative w-full justify-center'>
        <div className='absolute bottom-10 block'>
          <Message content='Creating document' severity='info' />
        </div>
      </div>
    </div>
  );
}

export default DocumentViewer;
