import { useCallback, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import LoadingSpinner from '../../components/LoadingSpinner';
import { useToken } from '../../hooks/hubspotHooks';

type ResponseError = {
  data: {
    message: string;
    hasAllScopes?: boolean;
    missingScopes?: string[];
    error?: string;
    authorizationUrl?: string;
  };
  status: number;
};
const Authorize = () => {
  const { checkValidity, isLoading, error: responseError, isUninitialized, isError, isSuccess } = useToken();
  const { data: errorData } = (responseError as ResponseError) || {};
  const navigate = useNavigate();
  const oAuthWindowRef = useRef<Window | null>(null);
  console.log('AUTHORIZE');
  useEffect(() => {
    if (isUninitialized) {
      checkValidity();
    }
  }, [checkValidity, isUninitialized]);

  const openAuthWindow = useCallback(() => {
    oAuthWindowRef.current = window.open(
      errorData?.authorizationUrl,
      '_blank',
      'fullscreen=no,height=900,width=700,menubar=no,resizable=yes'
    );

    const timer = setInterval(() => {
      if (oAuthWindowRef.current?.closed) {
        clearInterval(timer);
        if (!isUninitialized) checkValidity();
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [errorData, checkValidity, isUninitialized]);

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (errorData?.authorizationUrl && !oAuthWindowRef.current) {
      const cancel = openAuthWindow();
      return isError ? () => {} : cancel;
    }
  }, [openAuthWindow, errorData, isError]);

  return (
    <div className='flex flex-col h-screen bg-primary-50 justify-center items-center'>
      {/* CARD */}
      <div
        className='flex flex-col justify-stretch items-stretch bg-white rounded-lg shadow-lg'
        style={{ minWidth: '400px' }}
      >
        {/* CARD HEADER */}
        <div className='border-b border-b-neutral-200 w-full p-4'>
          <img src='./images/logo.svg' style={{ maxWidth: '100px' }} alt='documint logo' />
        </div>

        {/* CARD HEADER */}
        <div className='flex flex-col text-center p-8 pt-6  gap-6'>
          {isLoading ? (
            <LoadingSpinner />
          ) : isError ? (
            <div className=''>
              <h3 className='text-xl mb-3'>Authorization Needed</h3>
              <p className='text-neutral-500 text-sm mb-4'>
                A new window should appear requesting authorization.
                <br />
                If you don't see it click "Authorize" below.
              </p>
            </div>
          ) : (
            <>
              <Message
                title='Error'
                content={
                  errorData?.message || 'Unknown error occurred. Try closing this window and re-launching the app.'
                }
              />
            </>
          )}
        </div>

        {/* CARD FOOTER */}

        <div className='p-5 border-t border-t-neutral-200 flex justify-center gap-5'>
          <div>
            {errorData?.authorizationUrl && (
              <Link
                to={errorData?.authorizationUrl}
                className='p-button-link p-button  p-button-sm inline-flex items-center'
                target='_blank'
                onClick={openAuthWindow}
              >
                Authorize
              </Link>
            )}
          </div>

          <div className='flex flex-col justify-center items-center'>
            <Button size='small' icon='pi pi-sign-in' iconPos='right' label='Login' onClick={() => navigate('/')} />
            <p className='text-xs mt-3'>Click after authorization</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authorize;
