import { apiSlice } from '.';
import { GetApiKeyResponse, ValidateApiKeyParams, ValidateApiKeyResponse } from './types';
import { getCurrentParams } from './utils';

export const auth = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ sessionToken }) => ({
        url: 'auth/login',
        method: 'POST',
        headers: { Authorization: `Bearer ${sessionToken}` },
      }),
    }),

    // API KEYS
    getApiKey: builder.query<GetApiKeyResponse, void>({
      query: () => 'auth/api-keys',
      providesTags: ['ApiKey'],
    }),

    addApiKey: builder.mutation<string, string>({
      query: (apiKey) => ({
        url: 'auth/api-keys',
        body: { apiKey },
        method: 'PUT',
        params: getCurrentParams(),
      }),
      invalidatesTags: ['ApiKey', 'ApiKeyValidity'],
    }),

    checkApiKeyValidity: builder.query({
      query: () => ({ url: 'auth/api-keys/validity' }),
      providesTags: ['ApiKeyValidity'],
    }),

    validateApiKey: builder.mutation<ValidateApiKeyResponse, ValidateApiKeyParams | void>({
      query: (apiKey?) => ({
        url: 'auth/api-keys/validate',
        body: apiKey ? { apiKey } : undefined,
        method: 'POST',
      }),
      invalidatesTags: ['ApiKey', 'ApiKeyValidity'],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetApiKeyQuery,
  useLazyGetApiKeyQuery,
  useAddApiKeyMutation,
  useCheckApiKeyValidityQuery,
  useLazyCheckApiKeyValidityQuery,
  useValidateApiKeyMutation,
} = auth;
