import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

type State = {
  objectType: string | null;
  objectId: string | null;
};

const initialState: State = {
  objectType: null,
  objectId: null,
};

export const objectSlice = createSlice({
  name: 'object',
  initialState,
  reducers: {
    setObject(state, action: PayloadAction<State>) {
      state.objectId = action.payload.objectId;
      state.objectType = action.payload.objectType;
    },
    setObjectId(state, action: PayloadAction<State['objectId']>) {
      state.objectId = action.payload;
    },
    setObjectType(state, action: PayloadAction<State['objectType']>) {
      state.objectType = action.payload;
    },
  },
});

export const { setObject, setObjectId, setObjectType } = objectSlice.actions;

export const selectObjectType = (state: RootState) => state.object.objectType;
export const selectObjectId = (state: RootState) => state.object.objectId;

export default objectSlice.reducer;
