import { apiSlice } from '.';
import { GetPropertiesResponse } from './types';

const properties = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<GetPropertiesResponse, void>({
      query: () => '/hubspot/properties',
      providesTags: [{ id: 'List', type: 'Properties' }],
    }),
  }),
});

export const { useGetPropertiesQuery, useLazyGetPropertiesQuery } = properties;
