import { apiSlice } from './index';
import { GetTemplateParams, ListResponse, SingleResponse, Template, TemplatesQuery, UpdateParams } from './types';
import { getCurrentParams } from './utils';

export const templatesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTemplate: builder.query<SingleResponse<Template>['data'], GetTemplateParams>({
      query: (params) => {
        const { id, query = {} } = params;
        const { select = 'name,options,documentNamePattern,thumbnail,createdAt,updatedAt,isActive' } = query;
        return {
          url: `templates/${id}`,
          params: { ...query, select, ...getCurrentParams() },
        };
      },
      transformResponse: (response: SingleResponse<Template>) => {
        const { documentNamePattern = '', thumbnail } = response.data;
        // Add timestamp to thumbnail URL to bust cache
        let result: SingleResponse<Template>['data'] = {
          ...response.data,
          documentNamePattern,
          thumbnail: { ...thumbnail, url: thumbnail?.url + `?${Date.now()}` },
        };
        return result;
      },
      providesTags: (response, error, arg) => [{ id: arg.id, type: 'Template' as const }],
    }),
    listTemplates: builder.query<ListResponse<Template>, TemplatesQuery | void>({
      query: (query = { select: 'name,options,documentNamePattern,thumbnail,createdAt,updatedAt,isActive' }) => ({
        url: `templates`,
        params: { ...query, ...getCurrentParams() },
      }),
      transformResponse: (response: ListResponse<Template>) => {
        // Add timestamp to thumbnail URL to bust cache
        let result: ListResponse<Template> = {
          ...response,
          data: response?.data?.map(({ thumbnail, documentNamePattern = '', ...rest }) => ({
            ...rest,
            documentNamePattern,
            thumbnail: { ...thumbnail, url: thumbnail?.url + `?${Date.now()}` },
          })),
        };

        return result; //templatesAdapter.setAll(initialState, result.data);
      },
      providesTags: (response) =>
        response
          ? [
              { id: 'LIST', type: 'Template' as const },
              ...response?.data?.map(({ id }) => ({ id, type: 'Template' as const })),
            ]
          : [],
    }),
    updateTemplate: builder.mutation<SingleResponse<Template>, UpdateParams>({
      query: ({ id, update }) => ({ url: `templates/${id}`, method: 'PUT', body: update }),
      invalidatesTags: (result, error, arg) => [{ type: 'Template', id: arg.id }],
    }),
  }),
});

export const { useListTemplatesQuery, useUpdateTemplateMutation } = templatesApi;
