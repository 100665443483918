import { useState } from 'react';
import formatters from '../formatters';
import { PropertyNode, UseType } from '../types';
import { SelectItemOptionsType } from 'primereact/selectitem';
import Icon from './Icon';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';

interface Props {
  node: PropertyNode;
  onUseTypeChange: (value: UseType, node: PropertyNode) => void;
}

export function TokenColumnBody({ node, onUseTypeChange }: Props) {
  const [applyFormat, setApplyFormat] = useState<boolean>(false);
  const { key, data, children } = node || {};
  const { parent } = data;
  const iconKey = `${key}-1`;
  const isCollectionType = !!children;

  // add the selected "use type" (loop or first item) to the collection node's
  // child data attr for use in child nodes
  const useType = node?.useType || parent?.useType;
  if (isCollectionType) node?.children?.map((child) => (child.data.parent = { ...child.data.parent, useType }));

  // Create token value
  let tokenValue = '';

  if (isCollectionType) {
    tokenValue = useType === 'first' ? '' : data.name;
  } else {
    tokenValue = useType === 'first' ? `${parent?.id}.0.${data.name}` : `${data.name}`;
  }

  const formatter = data?.fieldType ? formatters[`${data.type}:${data.fieldType}`] : undefined;
  if (formatter && applyFormat) tokenValue = formatter(tokenValue);
  if (tokenValue && !(isCollectionType && useType === 'loop')) tokenValue = `{{${tokenValue}}}`;

  const tooltip =
    data?.type === 'collection' && useType === 'loop'
      ? `Use '${data.name}' as the list variable when looping/repeating an element in your template. 
         See Instructions for more details.`
      : data?.type === 'collection' && useType === 'first'
      ? ''
      : data?.parent && useType === 'loop'
      ? `Paste this inside element(s) looping over '${parent?.id}'`
      : 'Paste this token into your documint template';

  const useTypeOptions: SelectItemOptionsType = [
    { label: 'All items', value: 'loop' },
    { label: 'First item', value: 'first' },
  ];

  return (
    <div className='flex basis-full'>
      <div className='flex flex-auto items-center break-all overflow-x-auto'>
        {tokenValue && (
          <>
            <code className='bg-slate-100 border border-slate-200 py-0.5 px-2 rounded break-keep text-sm mx-0.5'>
              {tokenValue}
            </code>
            <Icon id={iconKey} tooltip={tooltip} />
          </>
        )}
      </div>

      <div className='shrink pl-2'>
        {isCollectionType && useType && (
          <Dropdown
            options={useTypeOptions}
            value={useType}
            pt={{ input: { style: { padding: '5px 10px' } } }}
            onChange={(e) => onUseTypeChange?.(e.target.value, node)}
          />
        )}

        {formatter && (
          <div className='flex items-center gap-3 flex-nowrap'>
            <label className='whitespace-nowrap'>Add formatter</label>
            <InputSwitch
              checked={applyFormat}
              onChange={(e) => setApplyFormat((prev) => !prev)}
              tooltip='Formatters are used to customize the format of date, time, number, currency, and percent values'
              tooltipOptions={{ showDelay: 500 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TokenColumnBody;
