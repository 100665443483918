import { useMemo } from 'react';
import { selectObjectId, selectObjectType } from '../store/objectSlice';
import { useAppSelector } from './reduxHooks';

export function useObject() {
  const objectId = useAppSelector(selectObjectId);
  const objectType = useAppSelector(selectObjectType);

  return useMemo(() => ({ objectId, objectType }), [objectId, objectType]);
}
