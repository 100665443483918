import { useMemo, useState } from 'react';

import { DataView, DataViewLayoutOptions, DataViewPassThroughOptions } from 'primereact/dataview';
import { useListTemplatesQuery } from '../../app/api/templates';
import { Template } from '../../app/api/types';

import SearchButtonInput from '../../components/SearchButtonInput';
import GridItem from './components/GridItem';
import ListItem from './components/ListItem';
import { Tooltip } from 'primereact/tooltip';
import { useDebounce } from 'primereact/hooks';
import { fuzzyMatch } from '../../utils';
import { useTemplates } from '../../hooks/templateHooks';
import { useNavigate } from 'react-router';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import TemplateListEmptyState from './components/TemplateListEmptyState';
import GridItemSkeleton from './components/GridItemSkeleton';

type ActiveOption = 'all' | 'active' | 'inactive';

const Templates = () => {
  const { refetch, isFetching } = useListTemplatesQuery();
  const { templates, selectTemplate } = useTemplates();
  const [layout, setLayout] = useState<any>('grid');
  const searchString = useDebounce('', 400);
  const searchValue = searchString[1];
  const setSearchValue = searchString[2];

  const [activeFilter, setActiveFilter] = useState<ActiveOption>('all');
  const navigate = useNavigate();

  const handleSelectTemplate = (id: string) => {
    const template = templates?.find((t) => t.id === id);
    if (!template) return;
    selectTemplate(template);
    navigate('/dashboard');
  };

  const handleReload = () => {
    refetch();
  };

  // Apply search and active filters
  const filteredTemplates = useMemo(() => {
    let result: Template[] | undefined = templates;
    if (activeFilter === 'active') {
      result = templates?.filter((t) => t.isActive === true);
    } else if (activeFilter === 'inactive') {
      result = templates?.filter((t) => t.isActive === false);
    }
    if (!searchValue || searchValue === '') return result;
    return result?.filter((template) => (template?.name ? fuzzyMatch(searchValue, template.name) : false));
  }, [searchValue, templates, activeFilter]);

  const hasSearchResults = filteredTemplates.length > 0;

  const activeOptions: { label: string; value: ActiveOption }[] = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ];

  const ptOptions: DataViewPassThroughOptions = {
    grid: {},
    header: { style: { padding: '.5em 1em' } },
    emptyMessage: {
      className: 'p-10 text-center w-full items-center flex justify-center',
      style: { minHeight: 'calc(100vh - 60px)' },
    },
    content: { style: { background: 'transparent' } },
    loadingOverlay: { style: { backgroundColor: 'rgba(255,255,255,.7)', zIndex: 100 } },
  };

  if (layout === 'grid')
    ptOptions.grid = {
      className: hasSearchResults ? 'grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 p-5' : '',
    };

  const listItem = (template: Template) => <ListItem template={template} onSelect={handleSelectTemplate} />;
  const gridItem = (template: Template) => <GridItem template={template} onSelect={handleSelectTemplate} />;
  const itemTemplate = layout === 'grid' ? gridItem : listItem;

  return (
    <div className='flex flex-col h-screen'>
      {/* HEADER */}
      <div className='grid grid-cols-3 py-2 px-1'>
        {/* LEFT */}
        <div className='flex justify-start items-center gap-3 '>
          <Dropdown
            options={activeOptions}
            value={activeFilter}
            onChange={(e) => setActiveFilter(e.target.value)}
            className='p-inputtext-sm'
          />
          <SearchButtonInput onChange={(v) => setSearchValue(v)} />
        </div>

        {/* CENTER */}
        <div className='flex justify-center items-center'>
          <h4 className='text-2xl font-medium text-slate-600'>Select a Template</h4>
        </div>

        {/* RIGHT */}
        <div className='flex items-center justify-end gap-3'>
          <Button
            size='small'
            onClick={handleReload}
            disabled={isFetching}
            icon='pi pi-refresh'
            outlined
            severity='secondary'
            loading={isFetching}
          />
          <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
        </div>
      </div>

      {/* CONTENT */}
      <div className='flex-auto overflow-y-auto border  bg-slate-50'>
        {isFetching ? (
          <div
            className='p-grid grid p-nogutter grid-nogutter grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 p-5'
            data-pc-section='grid'
          >
            {Array.apply(null, Array(20)).map(() => (
              <GridItemSkeleton />
            ))}
          </div>
        ) : templates?.length > 0 ? (
          <DataView
            value={isFetching ? [] : filteredTemplates}
            itemTemplate={itemTemplate}
            header={false}
            layout={layout}
            pt={ptOptions}
            loading={isFetching}
            className='h-full'
            emptyMessage="Couldn't find any templates"
            style={{ minHeight: '100%' }}
          />
        ) : (
          <div className='h-full flex justify-center items-center'>
            <TemplateListEmptyState onReload={handleReload} />
          </div>
        )}
        <Tooltip target='.active-badge' />
      </div>
    </div>
  );
};

export default Templates;
