import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ListResponse, Template } from '../app/api/types';

import { templatesApi } from '../app/api/templates';

const templatesAdapter = createEntityAdapter<Template>();

interface TemplateState extends ReturnType<typeof templatesAdapter.getInitialState> {
  selectedTemplateId: string | null;
}

const initialState: TemplateState = templatesAdapter.getInitialState({
  selectedTemplateId: null,
});

export const templateSlice = createSlice({
  name: 'template',
  initialState: initialState,
  reducers: {
    setSelectedTemplate(state, action: PayloadAction<string | null | undefined | Template>) {
      const id = typeof action.payload === 'string' ? action.payload : action.payload?.id ?? null;
      state.selectedTemplateId = id;
    },
    deselectTemplate(state) {
      state.selectedTemplateId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      templatesApi.endpoints.getTemplate.matchFulfilled,
      (state: TemplateState, action: PayloadAction<Template>) => {
        templatesAdapter.addOne(state, action.payload);
      },
    );
    builder.addMatcher(
      templatesApi.endpoints.listTemplates.matchFulfilled,
      (state: TemplateState, action: PayloadAction<ListResponse<Template>>) => {
        templatesAdapter.setAll(state, action.payload.data);
      },
    );
  },
});

export const { setSelectedTemplate, deselectTemplate } = templateSlice.actions;

export const selectSelectedTemplateId = (state: RootState) => state.template.selectedTemplateId;

export const {
  selectAll: selectAllTemplates,
  selectById: selectTemplateById,
  selectIds: selectTemplateIds,
} = templatesAdapter.getSelectors((state: RootState) => state.template ?? initialState);

export const templateReducer = templateSlice.reducer;
