import { Tag } from 'primereact/tag';
import { useTemplates } from '../../../hooks/templateHooks';
import { useCreatorMode } from '../../../hooks/uiHooks';
import { Button } from 'primereact/button';
import { MouseEvent } from 'react';

interface Props {
  onClick: () => void;
  onClickSettings?: () => void;
}

const SelectedTemplate = ({ onClick, onClickSettings }: Props) => {
  const { selectedTemplate } = useTemplates();
  const [creatorMode] = useCreatorMode();

  if (!selectedTemplate) return <></>;

  const handleSettingsClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClickSettings?.();
  };

  return (
    <div
      onClick={onClick}
      className='flex flex-col cursor-pointer align-stretch overflow-hidden bg-slate-100 hover:bg-slate-200'
    >
      <div className='flex justify-center gap-2 p-5 relative'>
        {creatorMode && (
          <Button
            text
            icon='pi pi-cog text-neutral-500'
            pt={{ icon: { style: { fontSize: '22px' } } }}
            className='absolute right-1 top-1'
            style={{ padding: '5px' }}
            onClick={handleSettingsClick}
          />
        )}
        <img
          src={selectedTemplate?.thumbnail?.url}
          alt='selected template thumbnail'
          className='shadow-md'
          style={{ border: 'solid 2px #fff', width: '150px' }}
        />
      </div>
      <div className='flex justify-between items-center px-5 py-3' style={{ backgroundColor: '#4f82a91a' }}>
        <h4 className='text-lg text-slate-800'>{selectedTemplate.name}</h4>
        {selectedTemplate.isActive ? (
          <Tag severity='success' className='py-5' value='Active' />
        ) : (
          <Tag severity='danger' className='py-5' value='Inactive' />
        )}
      </div>
    </div>
  );
};

export default SelectedTemplate;
